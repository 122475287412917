import * as React from "react"
import { useState, useEffect } from "react"
import TaskListRow from "./taskListRow"
import AxiosInstance from "../../../services/axiosInstance"
import "../../../css/jquery.dataTables.min.css"
import "../tasks.css"
import { Link } from "gatsby"

const TaskTable = ({
  data,
  setDeleteTaskRow,
  setSearchTransaction,
  transactionTypeId,
  setArchivedTaskRow,
  filterTag,
  filterContact,
}) => {
  const [selectedTasks, setSelectedTasks] = useState([])
  const [projectTypes, setProjectTypes] = useState([])
  const [transactionType, setTransactionType] = useState("All")
  const [transactionIcon, setTransactionIcon] = useState("check2-square")

  const projectTypesData = () => {
    AxiosInstance.get("/settings/transaction-type")
      .then(response => {
        setProjectTypes(response.data.items)

        if (transactionTypeId) {
          const transactionDetail = response.data.items.filter(item => item.id == transactionTypeId)
          setTransactionType(transactionDetail[0]?.dealTypeLabel)
          setTransactionIcon(transactionDetail[0]?.icon)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    projectTypesData()
  }, [])

  return (
    <React.Fragment>
      <table id="tblTasks" className="table dataTable" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th width="5%">&nbsp;</th>

            <th width="60%">
              <div className="btn-group btnFilterTaskContainer" role="group">
                <button
                  type="button"
                  className="btn btnFilterTask dropdown-toggle no-dropdown-arrow"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className={`bi bi-${transactionIcon}`}></i> {transactionType}
                </button>
                <ul className="dropdown-menu">
                  <li
                    onClick={() => {
                      setTransactionType("All")
                      setTransactionIcon("check2-square")
                    }}
                  >
                    <a className="dropdown-item" href={transactionTypeId ? "/jobs" : "#"}>
                      <i className="bi bi-check2-square"></i> All
                    </a>
                  </li>

                  {projectTypes.map((projectType, index) => {
                    const filterLink = [
                      projectType.id ? `transactionTypeId=${projectType.id}` : "",
                      filterTag ? `tag=${encodeURIComponent(filterTag)}` : "",
                      filterContact ? `contact=${filterContact}` : "",
                    ]
                      .filter(Boolean)
                      .join("&")

                    const fullLink = filterLink ? `?${filterLink}` : ""

                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setSearchTransaction(projectType.id)
                          setTransactionType(projectType.dealTypeLabel)
                          setTransactionIcon(projectType.icon)
                        }}
                      >
                        <Link className="dropdown-item" to={`/jobs${fullLink}`}>
                          <i className={`bi bi-${projectType.icon}`}></i> {projectType.dealTypeLabel}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
              {/* <button
                type="button"
                className="btn btn-sm btnTHSoryByTags float-end me-3"
                onClick="if (!window.__cfRLUnblockHandlers) return false; event.stopPropagation();"
                data-bs-toggle="modal"
                data-bs-target="#TasksSortByTagModal"
                title="Advanced Tag Search"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Advanced Tag Search"
                  aria-label="Advanced Tag Search"
                >
                  <i className="bi bi-tags-fill"></i>
                </span>
              </button> */}
            </th>

            <th width="15%" className="d-none d-md-table-cell td-urgency">
              Urgency
            </th>
            <th width="20%" className="text-end d-none d-lg-table-cell">
              Last Activity / Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((task, index) => {
            return (
              <TaskListRow
                task={task}
                key={`task_row_${index}`}
                setDeleteTaskRow={setDeleteTaskRow}
                selectedTasks={selectedTasks}
                setSelectedTasks={setSelectedTasks}
                setArchivedTaskRow={setArchivedTaskRow}
                transactionTypeId={transactionTypeId}
                filterTag={filterTag}
                filterContact={filterContact}
              />
            )
          })}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default TaskTable
