import * as React from "react"
import { useEffect, useReducer, useCallback } from "react"
import debounce from "lodash/debounce"
import { deleteTask, archivedTask } from "./taskListHelper"

const INTERSECTION_THRESHOLD = 5
const LOAD_DELAY_MS = 100

const reducer = (state, action) => {
  switch (action.type) {
    case "set": {
      return {
        ...state,
        ...action.payload,
      }
    }
    case "onGrabData": {
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload.data],
        currentPage: action.payload.data.length === 0 ? state.currentPage : state.currentPage + 1,
        pages_count: action.payload.pages_count,
        lastOffSet:
          action.payload.data.length > 0
            ? action.payload.data[action.payload.data.length - 1].id
            : state.lastOffSet,
      }
    }
    case "onSearch": {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        currentPage: state.currentPage,
        pages_count: action.payload.pages_count,
        lastOffSet:
          action.payload.data.length > 0
            ? action.payload.data[action.payload.data.length - 1].id
            : state.lastOffSet,
      }
    }
    case "deleteTaskIndex": {
      let data = action.payload.stateData.filter(task => task.id !== action.payload.deleteTaskId)

      return {
        ...state,
        data: [...data],
      }
    }
    case "archivedTaskIndex": {
      let data = action.payload.stateData.filter(task => task.id !== action.payload.archivedTaskId)

      return {
        ...state,
        data: [...data],
      }
    }
    default:
      return state
  }
}

const TaskLazyLoad = ({
  triggerRef,
  onGrabData,
  deleteTaskRow,
  setDeleteTaskRow,
  searchTerm,
  setSearchTerm,
  searchTransaction,
  setSearchTransaction,
  options,
  transactionTypeId,
  archivedTaskRow,
  setArchivedTaskRow,
  filterTag,
  searchTag,
  setSearchTag,
  filterContact,
  searchContact,
  setSearchContact,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    currentPage: 1,
    pages_count: null,
    lastOffSet: 0,
    data: [],
  })

  const _handleEntry = async entry => {
    const boundingRect = entry.boundingClientRect
    const intersectionRect = entry.intersectionRect
    let deleteTaskId = deleteTaskRow
    let archivedTaskId = archivedTaskRow

    if (
      (!state.loading &&
        entry.isIntersecting &&
        intersectionRect.bottom - boundingRect.bottom <= INTERSECTION_THRESHOLD &&
        !deleteTaskRow) ||
      searchTerm ||
      searchTransaction
    ) {
      dispatch({ type: "set", payload: { loading: true } })

      const responseData = await onGrabData(
        state.currentPage,
        state.pages_count,
        searchTerm,
        searchTransaction,
        filterTag,
        filterContact
      )
      const data = responseData.items
      const pages_count = responseData.pages_count

      if (searchTerm || searchTransaction || searchTag || searchContact) {
        if (searchTerm) {
          setSearchTerm(null)
        }
        if (searchTransaction) {
          setSearchTransaction(null)
        }

        if (searchTag) {
          setSearchTag(null)
        }
        if (searchContact) {
          setSearchContact(null)
        }
        dispatch({ type: "onSearch", payload: { data, pages_count } })
      } else {
        if (!transactionTypeId && !filterTag && !filterContact) {
          dispatch({ type: "onGrabData", payload: { data, pages_count } })
        }
      }
    }

    if (deleteTaskRow) {
      setDeleteTaskRow(null)
      const responseData = await deleteTask(deleteTaskId, state.data)
      let stateData = [...responseData]
      dispatch({ type: "deleteTaskIndex", payload: { stateData, deleteTaskId } })
    }

    if (archivedTaskRow) {
      setArchivedTaskRow(null)
      const responseData = await archivedTask(archivedTaskId, state.data)
      let stateData = [...responseData]
      dispatch({ type: "archivedTaskIndex", payload: { stateData, archivedTaskId } })
    }
  }
  const handleEntry = debounce(_handleEntry, LOAD_DELAY_MS)

  const onIntersect = useCallback(
    entries => {
      handleEntry(entries[0])
    },
    [handleEntry]
  )

  useEffect(() => {
    if (triggerRef.current) {
      const container = triggerRef.current
      const observer = new IntersectionObserver(onIntersect, options)

      observer.observe(container)

      return () => {
        observer.disconnect()
      }
    }
  }, [triggerRef, onIntersect, options])

  return state
}

export default TaskLazyLoad
