import AxiosInstance from "../../../services/axiosInstance"
import {
  ON_HOLD,
  SLOW_DAY,
  IMPORTANT,
  MEDIUM,
  ASAP,
  HIGH_PRIORITY,
  URGENT,
  SEVERE,
  CRITICAL,
  EMERGENCY,
} from "../taskHelper"

export const onGrabData = (
  currentPage,
  pages_count,
  searchTerm,
  transactionTypeId,
  filterTag,
  filterContact
) => {
  return new Promise(async resolve => {
    let data = {
      items: [],
      pages_count: pages_count,
    }

    if (searchTerm || transactionTypeId || filterTag || filterContact) {
      currentPage = 1
      pages_count = 0
    }

    let params = {
      page: currentPage,
      search: searchTerm,
    }

    if (transactionTypeId) {
      params.transactionTypeId = transactionTypeId
    }

    if (filterTag) {
      params.filterTag = filterTag
    }

    if (filterContact) {
      params.filterContact = filterContact
    }

    if (
      pages_count === null ||
      currentPage <= pages_count ||
      searchTerm ||
      transactionTypeId ||
      filterTag ||
      filterContact
    ) {
      data = await AxiosInstance.get(`/task/`, {
        params,
      }).then(response => {
        return response.data
      })
    }

    resolve(data, pages_count)
  })
}

export const deleteTask = (taskId, data) => {
  return new Promise(async resolve => {
    let success = false

    await AxiosInstance.delete(`/task/${taskId}`).then(response => {
      if (response.data) {
        success = response.data.success
      }
    })

    resolve(data, success)
  })
}

export const archivedTask = (taskId, data) => {
  return new Promise(async resolve => {
    let success = false

    await AxiosInstance.post(`task/update-archived-status/${taskId}`, {
      isArchived: 1,
    }).then(response => {
      if (response.data) {
        success = response.data.success
      }
    })

    resolve(data, success)
  })
}

export const getTaskUrgencyClass = urgency => {
  switch (urgency) {
    case ON_HOLD:
      return "on_hold"
    case SLOW_DAY:
      return "slowDay"
    case IMPORTANT:
      return "important"
    case MEDIUM:
      return "medium"
    case ASAP:
      return "asap"
    case HIGH_PRIORITY:
      return "high_priority"
    case URGENT:
      return "urgent"
    case SEVERE:
      return "severe"
    case CRITICAL:
      return "critical"
    case EMERGENCY:
      return "emergency"
    default:
      return ""
  }
}

export const getTaskUrgencyIcon = urgency => {
  switch (urgency) {
    case ON_HOLD:
      return "moon"
    case SLOW_DAY:
      return "hourglass"
    case IMPORTANT:
      return "star"
    case MEDIUM:
      return "star-fill"
    case ASAP:
      return "heart-fill"
    case HIGH_PRIORITY:
      return "exclamation-lg"
    case URGENT:
      return "exclamation-circle-fill"
    case SEVERE:
      return "exclamation-triangle-fill"
    case CRITICAL:
      return "fire"
    case EMERGENCY:
      return "fire"
    default:
      return ""
  }
}

export const getTaskStatusClass = status => {
  switch (status) {
    case "New":
      return "new"
    case "Claimed":
      return "claimed"
    case "In Process":
      return "in_progress"
    case "Complete Pending Verification":
      return "pending_verification"
    case "Revisions":
      return "revision"
    case "Closed Successful":
      return "closed_successful"
    case "Specs / Clarification Needed":
      return "clarification_needed"
    case "Deliverables Required":
      return "deliverables_required"
    case "Prerequisite / Dependency":
      return "prerequisite"
    default:
      return "new"
  }
}

export const getTaskStatusIcon = status => {
  switch (status) {
    case "New":
      return "app"
    case "Claimed":
      return "clock"
    case "In Process":
      return "hourglass-split"
    case "Complete Pending Verification":
      return "check2-square"
    case "Revisions":
      return "exclamation-triangle-fill"
    case "Closed Successful":
      return "flag-fill"
    case "Specs / Clarification Needed":
      return "chat-fill"
    case "Deliverables Required":
      return "cloud-arrow-up-fill"
    case "Prerequisite / Dependency":
      return "clock"
    default:
      return "app"
  }
}
