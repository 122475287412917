import * as React from "react"
import { Link } from "gatsby"
import TimeAgo from "timeago-react"
import { timeFormatter } from "../../../services/timeHelper"
import TagListView from "./tagListView"
import {
  getTaskUrgencyClass,
  getTaskUrgencyIcon,
  getTaskStatusClass,
  getTaskStatusIcon,
} from "./taskListHelper"
import { PIPELINE, taskStatusesData, taskUrgencyData } from "../taskHelper"
import { useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const TaskListRowPipeline = ({
  task,
  setDeleteTaskRow,
  setSelectedTasks,
  selectedTasks,
  setArchivedTaskRow,
  transactionTypeId,
  filterTag,
  filterContact,
}) => {
  const taskId = "T_ID" + task.id

  const [statusClass, setStatusClass] = useState(""),
    [statusIcon, setStatusIcon] = useState(""),
    [urgency, setUrgency] = useState(""),
    [urgencyClass, setUrgencyClass] = useState(""),
    [urgencyIcon, setUrgencyIcon] = useState(""),
    [taskStatusesNewData, setTaskStatusesNewData] = useState(taskStatusesData),
    [isDefaultStatusData, setIsDefaultStatusData] = useState(true),
    [defaultContactImage, setDefaultContactImage] = useState("StaticImages/avatar.gif"),
    [dynamicWidth, setDynamicWidth] = useState(100)

  const updateTaskStatus = (taskStatusValue, taskId, transactionTypeStatusId) => {
    let taskStatusObject = {}

    if (transactionTypeStatusId) {
      taskStatusObject.transactionTypeStatus = transactionTypeStatusId
    } else {
      taskStatusObject.status = taskStatusValue
    }

    AxiosInstance.post(`task/update-status/${taskId}`, taskStatusObject)
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
  }

  const updateTaskUrgency = (taskUrgencyValue, taskId) => {
    AxiosInstance.post(`task/update-urgency/${taskId}`, {
      urgency: taskUrgencyValue,
    })
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
  }

  const getTransactionDetail = transactionTypeId => {
    if (transactionTypeId) {
      return new Promise(async () => {
        await AxiosInstance.get(`/settings/transaction-type/detail/${transactionTypeId}`).then(function (
          response
        ) {
          if (response.status === 200) {
            const transactionType = response.data.type

            setTaskStatusesNewData(transactionType.statuses)
            setIsDefaultStatusData(false)
          }
        })
      })
    }
  }

  const updateTransactionTypeStatus = (workflowStatus, taskId) => {
    if (!workflowStatus) {
      alert("Please select workflow status.")
    }

    let workflowStatusObj = {
      workflow_status: workflowStatus,
    }

    return new Promise(async () => {
      try {
        const response = await AxiosInstance.post(`/task/edit-workflow-status/${taskId}`, workflowStatusObj)
        if (response.status === 201) {
        }
      } catch (error) {
        console.error(error)
        alert("There is some error. Please try again later.")
      }
    })
  }

  return (
    <tr>
      <td className="tableColChkDel">
        <div className="tableColChkDelBox">
          <input
            type="checkbox"
            name="fileid[]"
            id={taskId}
            className="css-checkbox"
            onChange={e => {
              if (e.target.checked) {
                if (!selectedTasks.includes(task.id)) {
                  setSelectedTasks([...selectedTasks, task.id])
                }
              } else {
                const selectedFiles = selectedTasks.filter(select => select !== task.id)
                selectedFiles === [] ? setSelectedTasks([]) : setSelectedTasks(selectedFiles)
              }
            }}
          />
          <label htmlFor={taskId} className="css-checkbox-label" />
          <span
            className="tableColChkSpan transactionTypeIconColor"
            style={{
              color: task.transactionType?.iconColor,
              backgroundColor: task.transactionType?.iconBackgroundColor,
            }}
          >
            <i className={`bi bi-${task.transactionType ? task.transactionType.icon : "check-square"}`} />
          </span>
        </div>
      </td>
      <td>
        {task.metaJson?.workflow_status?.length > 0 && (
          <div className="pipelineViewRaveScoreContainer mb-2">
            <div className="activeWorkroomStatusBar">
              <ul className="workflow-wizard">
                {task.metaJson.workflow_status.map((transactionData, transactionIndex) => {
                  const status = `SaleStatus_${
                    task.metaJson.workflow_status.length > 0 &&
                    task.metaJson.workflow_status[transactionIndex]?.id === transactionData.id
                      ? task.metaJson.workflow_status[transactionIndex].status
                      : "Pending"
                  }`

                  var transactionStatus = ""
                  if (task?.transactionType?.statuses) {
                    transactionStatus = task.transactionType?.statuses.find(
                      status => status.id === transactionData.id
                    )
                  }

                  return (
                    <OverlayTrigger
                      key={transactionIndex}
                      overlay={
                        task.metaJson.workflow_status?.length != transactionIndex + 1 ? (
                          <Tooltip>{transactionStatus?.label}</Tooltip>
                        ) : (
                          <span></span>
                        )
                      }
                    >
                      <li
                        className={`SaleStatus_${transactionData.status}`}
                        key={transactionIndex}
                        style={{
                          width: `${dynamicWidth / (task.metaJson.workflow_status?.length + 1)}%`,
                        }}
                        id={transactionIndex}
                      >
                        <i
                          className={`bi bi-${
                            task.metaJson.workflow_status?.length != transactionIndex + 1
                              ? transactionStatus?.icon
                              : "trophy-fill"
                          }`}
                        />
                        <select
                          className="form-select workflow-wizard-select"
                          defaultValue={status}
                          onChange={e => {
                            const newStatus = e.target.value
                            const parentLi = e.target.parentNode
                            parentLi.className = newStatus

                            task.metaJson.workflow_status[transactionIndex] = {
                              id: task.metaJson.workflow_status[transactionIndex].id,
                              status: newStatus.replace("SaleStatus_", ""),
                            }
                            updateTransactionTypeStatus(task.metaJson.workflow_status, task.id)
                          }}
                        >
                          <option value="SaleStatus_Pending">Not Started</option>
                          <option value="SaleStatus_Processing">In Process / Pending</option>
                          <option value="SaleStatus_Complete">Complete</option>
                          <option value="SaleStatus_Waiting_On">Waiting on...</option>
                          <option value="SaleStatus_Error">Problem / Other / See Notes</option>
                        </select>
                      </li>
                    </OverlayTrigger>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
        {task.urgency && (
          <div className="colTaskUrgency d-inline-block">
            <div className="btn-group urgencyStatusBtnGroup" role="group">
              <button
                type="button"
                className={`btn dropdown-toggle no-dropdown-arrow urgencyStatusContainer urgencyStatus_${
                  urgencyClass ? urgencyClass : getTaskUrgencyClass(task.urgency)
                }`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className={`bi bi-${urgencyIcon ? urgencyIcon : getTaskUrgencyIcon(task.urgency)}`} />{" "}
                {urgency ? urgency : task.urgency}
              </button>
              <ul className={`dropdown-menu hiddenStatus`}>
                {taskUrgencyData.map((taskUrgency, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setUrgency(taskUrgency.LABEL)
                      setUrgencyClass(taskUrgency.CLASS)
                      setUrgencyIcon(taskUrgency.ICON)
                      updateTaskUrgency(taskUrgency.LABEL, task.id)
                    }}
                  >
                    <a className="dropdown-item" href="#">
                      <span className={`urgencyStatus_${taskUrgency.CLASS}`}>
                        <i className={`bi bi-${taskUrgency.ICON}`} />
                      </span>{" "}
                      {taskUrgency.LABEL}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}{" "}
        <Link to={`/jobs/detail/${task.id}`}>{task.title}</Link>
        &nbsp;
        {task.taskContacts.length > 0 && (
          <ul className="user-card-list ms-1 mb-1">
            {task.taskContacts.map((taskContactData, taskContactIndex) => {
              const filterLink = [
                `view=${PIPELINE}`,
                transactionTypeId ? `transactionTypeId=${transactionTypeId}` : "",
                filterTag ? `tag=${encodeURIComponent(filterTag)}` : "",
                taskContactData.contact.id ? `contact=${taskContactData.contact.id}` : "",
              ]
                .filter(Boolean)
                .join("&")

              const fullLink = filterLink ? `?${filterLink}` : ""
              return (
                taskContactData.contact.name && (
                  <li key={taskContactIndex}>
                    <Link to={`/contacts/detail/${taskContactData.contact?.id}`}>
                      <img
                        src={`${process.env.GATSBY_WRANGLER_URL}${
                          taskContactData.contact?.profilePic
                            ? taskContactData.contact.profilePic
                            : defaultContactImage
                        }`}
                        alt=""
                        className="user_avatar"
                      />
                      {`${taskContactData.contact?.name}${
                        taskContactData.settingRole ? `, ${taskContactData.settingRole.role}` : ""
                      }`}
                    </Link>
                    <a href={`${fullLink}`}>
                      {" "}
                      <i className="bi bi-filter"></i>{" "}
                    </a>
                  </li>
                )
              )
            })}
          </ul>
        )}{" "}
        {task.tags.length > 0 && (
          <TagListView
            tags={task.tags}
            taskId={task.id}
            transactionTypeId={transactionTypeId}
            listViewType={"pipeline"}
            filterContact={filterContact}
          />
        )}
      </td>

      <td className="text-end d-none d-lg-table-cell">
        <div className="lastActivityBox">
          <TimeAgo datetime={timeFormatter(task.updatedAt)} />
        </div>

        <div className="statusActionBox">
          <button
            className="btn btnTaskStatus btn-dark"
            title="Archive"
            onClick={() => {
              setArchivedTaskRow(task.id)
            }}
          >
            <i className="bi bi-archive-fill" />
          </button>
          <button
            type="button"
            className={`btn btnTaskStatus taskStatus_${
              statusClass
                ? statusClass
                : task.transactionTypeStatus?.id
                ? "defaultStatusColor"
                : getTaskStatusClass(task.status)
            }`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => getTransactionDetail(task.transactionType?.id)}
          >
            <i
              className={`bi bi-${
                statusIcon
                  ? statusIcon
                  : task.transactionTypeStatus?.icon
                  ? task.transactionTypeStatus?.icon
                  : getTaskStatusIcon(task.status)
              }`}
            />
          </button>
          <ul className={`dropdown-menu hiddenStatus`}>
            {taskStatusesNewData.map((taskStatus, index) => (
              <li
                key={index}
                value={taskStatus.label}
                onClick={e => {
                  setStatusClass(taskStatus.class)
                  setStatusIcon(taskStatus.icon)
                  updateTaskStatus(taskStatus.label, task.id, taskStatus.id)
                  e.target.closest("ul.hiddenStatus").classList.remove("show")
                }}
              >
                <a className="dropdown-item" href="#">
                  <span
                    className={`${
                      isDefaultStatusData ? `taskStatus_${taskStatus.class}` : "transactionTypeIconColor"
                    }`}
                  >
                    <i className={`bi bi-${taskStatus.icon}`} />
                  </span>{" "}
                  {taskStatus.label}
                </a>
              </li>
            ))}
          </ul>

          <a
            className="btn btn-delete"
            data-toggle="tooltip"
            data-placement="bottom"
            data-animation="false"
            title="Delete"
            onClick={() => {
              setDeleteTaskRow(task.id)
            }}
          >
            <i className="bi bi-trash-fill" />
          </a>
        </div>
      </td>
    </tr>
  )
}

export default TaskListRowPipeline
