import * as React from "react"
import PrivateRoute from "../../components/privateRoute"
import List from "../../components/Tasks/Listing/list"
import ReduxWrapper from "../../redux/reduxWrapper"

const Tasks = props => {
  let searchUrl = props.location.search

  const getRequestData = new URLSearchParams(searchUrl)
  const transactionTypeId = getRequestData.get("transactionTypeId")
  const filterTag = getRequestData.get("tag")
  const filterContact = getRequestData.get("contact")
  const listViewType = getRequestData.get("view")

  return (
    <PrivateRoute
      component={List}
      location={props.location}
      addJob={searchUrl.indexOf("addJob") === 1}
      transactionTypeId={transactionTypeId}
      listViewType={listViewType}
      filterTag={filterTag}
      filterContact={filterContact}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<Tasks {...props} />} />
export default WrappedPage

export const Head = () => <title>Tasks List</title>
